function getElementOffset(elem) {
  // eslint-disable-next-line no-param-reassign
  if (!elem) elem = this

  let x = elem.offsetLeft
  let y = elem.offsetTop

  // eslint-disable-next-line no-param-reassign, no-cond-assign
  while ((elem = elem.offsetParent)) {
    x += elem.offsetLeft
    y += elem.offsetTop
  }

  return { left: x, top: y }
}

/**
 * Convenient function that will scroll an element into view with an offset
 * to account for the sticky secondary nav menu.
 *
 * Usage:
 * scrollIntoView(location.hash)
 */
export default function scrollIntoView(selector, { offset = 80, animate = true } = {}) {
  if (!selector || !document) {
    return
  }

  try {
    const element = document.querySelector(selector)

    // Focus the element to maintain good accessibility
    if (element && typeof element.focus === 'function') {
      const x = window.scrollX
      const y = window.scrollY

      // Modern browsers will not scroll thanks to the options
      element.focus({
        preventScroll: true,
      })

      // Legacy browsers will scroll and restore the scroll position
      if (x !== window.scrollX || y !== window.scrollY) {
        window.scrollTo(x, y)
      }
    }

    // Smooth scroll to the previously focused element
    if (element) {
      const elementOffset = getElementOffset(element)
      const offsetPosition = elementOffset.top - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: animate ? 'smooth' : 'auto',
      })
    }
  } catch (err) {
    // noop
  }
}
