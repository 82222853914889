/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import scrollIntoView from './src/utilities/scrollIntoView'

export const onClientEntry = async () => {
  // This polyfill is required to make the site work on Safari 12.0
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

// This piece of code fixes an issue where LiveEngage chat buttons would
// not appear on HTML History API change (or client-side only navigation)
// ref: https://developers.liveperson.com/le-tag-new-page-refresh.html
export const onRouteUpdate = ({ location }) => {
  if (window.lpTag && typeof window.lpTag.newPage === 'function') {
    window.lpTag.newPage(location.href)
  }

  // Scroll to a section with offset
  if (location.hash && location.hash.indexOf('#section-') === 0) {
    scrollIntoView(location.hash)
  }
}

export { wrapPageElement } from './wrapPageElement'
export { wrapRootElement } from './wrapRootElement'
