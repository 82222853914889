import { useTranslation } from 'react-i18next'

const LanguageActivator = ({ children, pageContext }) => {
  const [_t, i18n] = useTranslation()
  const langKey = pageContext.langKey || 'en-US'
  const resources = pageContext.localeResources || {}

  // Add resource bundle for this page if missing
  const [lang] = langKey.split('-')
  if (!i18n.hasResourceBundle(lang, 'common')) {
    i18n.addResourceBundle(lang, 'common', resources, true, true)
  }

  // Switch the language on page change
  if (i18n.language !== langKey) {
    i18n.changeLanguage(langKey)
  }

  return children
}

export function wrapPageElement({ element, props }) {
  return <LanguageActivator {...props}>{element}</LanguageActivator>
}
