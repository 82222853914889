/* eslint global-require: "off" */

import i18n from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'

const options = {
  debug: process.env.NODE_ENV === 'development',

  // Language to use (overrides detected language)
  lng: 'en-US',
  // Fallback Language that will be used if translations in selected lng are not available
  fallbackLng: 'en-US',
  // Ignore the country code when looking up the locale file
  load: 'languageOnly',

  // Array of namespaces to load
  ns: [],
  // Default namespace used if not passed to translation function
  defaultNS: 'common',

  // Array of allowed, non-explicit languages w/o country codes
  whitelist: ['en', 'es'],
  nonExplicitWhitelist: true,

  // Whether to load locales asynchronously immediately after calling init routine
  // https://www.i18next.com/overview/configuration-options#initimmediate
  initImmediate: false,

  // Whether to save missing keys through the backend
  saveMissing: false,

  interpolation: {
    // not needed for react as it escapes by default
    escapeValue: false,
  },
}

// Initialize React integration
i18n.use(initReactI18next)

// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
if (!i18n.isInitialized) {
  i18n.init(options)
}

const Root = ({ children }) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

export function wrapRootElement({ element }) {
  return <Root>{element}</Root>
}
